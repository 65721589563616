// Here you can add other styles
html {
  font-size: 14px; // Adjust this value as needed
  font-weight: 500; // Adjust this value as needed
}

.custom-table {
  font-size: 0.875rem;
  padding: 0.5rem;
}

.custom-table th,
.custom-table td {
  padding: 0.5rem;
}

.custom-table th:last-child,
.custom-table td:last-child {
  border-right: none;
}

.custom-table th {
  cursor: pointer;
  border-right: 1px solid #dee2e6; // Add this line for the right border
}

.custom-table th,
.custom-table td {
  text-align: left;
  white-space: nowrap;
}

.custom-table th.center,
.custom-table td.center {
  text-align: center;
}

.custom-table-icon-button {
  transform: scale(1.2);
  color: rgba(0, 0, 0, 0.2);
  background-color: transparent;
  transition: color 0.3s, background-color 0.3s; // Ensure background-color transition is included
  line-height: 0;
  padding: 0 0.5rem; // Add left-right padding
  border: none; // Ensure no border is applied on hover
}

.custom-table-icon-button:hover {
  color: rgba(0, 0, 0, 1);
  background-color: transparent !important; // Use !important to override other styles
  border: none; // Ensure no border is applied on hover
}

.fera-table {
  font-size: 0.875rem;
  padding: 0.5rem;
}

.fera-table th,
.fera-table td {
  padding: 0.5rem;
}

.fera-table th:last-child,
.fera-table td:last-child {
  border-right: none;
}

.fera-table th {
  cursor: pointer;
  border-right: 1px solid #dee2e6; // Add this line for the right border
}

.fera-table th,
.fera-table td {
  text-align: left;
  white-space: nowrap;
}

.fera-table th.center,
.fera-table td.center {
  text-align: center;
}

.fera-table-icon-button {
  transform: scale(1.2);
  color: rgba(0, 0, 0, 0.2);
  background-color: transparent;
  transition: color 0.3s, background-color 0.3s; // Ensure background-color transition is included
  line-height: 0;
  padding: 0 0.5rem; // Add left-right padding
  border: none; // Ensure no border is applied on hover
}

.fera-table-icon-button:hover {
  color: rgba(0, 0, 0, 1);
  background-color: transparent !important; // Use !important to override other styles
  border: none; // Ensure no border is applied on hover
}

.button-click-effect.rotate {
  transform: rotate(360deg);
  transition: transform 0.5s ease; // Add background-color transition
}

.button-click-effect:hover {
  color: blue; /* Change the text color to blue */
}

.button-click-effect:hover svg {
  fill: blue; /* Change the icon color to blue */
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.rotate-icon {
  animation: rotate 1s linear;
  animation-timing-function: ease-out;
}

.preprocess-button-click-effect {
  background-color: #6c757d;
  color: white;
  transition: background-color 1s ease;
}

.preprocess-button-click-effect:hover {
  background-color: #0047ab; /* Cobalt blue */
  color: white;
}

/* Add this CSS to your stylesheet */
.highlight-row {
  background: #be3434 !important; /* Faded silvery blue */
}

.clickable-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clickable-row:hover {
  background-color: #f1f1f1; /* Directly set the hover color */
}

.sidebar {
  width: 225px; // Adjust this value as needed
  font-size: 17px; // Adjust this value as needed
}

.sidebar-fixed {
  width: 225px; // Adjust this value as needed
  font-size: 17px; // Adjust this value as needed
}

:root {
  /* CSS HEX */
  --prussian-blue: #06304dff;
  --white: #fefefeff;
  --paynes-gray: #466e87ff;
  --cadet-gray: #809dadff;
  --oxford-blue: #001932ff;
}

.workflow-node-card {
  border-radius: 5px;
  background-color: #3c4b64;
  border: 1px solid var(--sidebar-border-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.workflow-node-card-header {
  background-color: #303c54;
  color: white;
  border-radius: 5px 5px 0 0;
  font-size: 0.65rem;
  font-weight: var(--cui-body-font-family);
  padding: 0.3rem 1rem 0.1rem 1rem; // Top 0.3rem, Right 1rem, Bottom 0.1rem, Left 1rem
  text-align: center; // Center the text
}

.workflow-node-text {
  background-color: #3c4b64;
  color: white;
  border-radius: 5px 5px 0 0;
  font-size: 0.7rem;
  font-weight: var(--cui-body-font-family);
}

.workflow-node-label {
  background-color: transparent;
  color: black;
  position: absolute;
  font-size: 0.7rem;
  font-weight: var(--cui-body-font-family);
  white-space: nowrap; /* Prevent text from wrapping */
  top: -14px; /* Adjust this value as needed to shift up */
  text-align: center;
}

.workflow-node-card-body {
  padding: 0.5rem;
}

.workflow-node-form-input,
.workflow-node-form-select {
  font-size: 0.6rem;
}

.workflow-node-form-select option {
  font-size: 0.6rem;
}

.workflow-node-form-select .dropdown-indicator {
  padding: 0px !important; /* Adjust the padding */
  min-width: 5px !important; /* Set minimum width */
  min-height: 50px !important; /* Set minimum height */
  max-width: 100px !important; /* Set maximum width */
  max-height: 10px !important; /* Set maximum height */
  align-items: center !important; /* Center vertically */
  justify-content: center !important; /* Center horizontally */
}

.workflow-node-button {
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
}

.workflow-node-radio-button {
  .form-check-input {
    width: 0.5rem;
    height: 0.5rem;
  }

  .form-check-label {
    font-size: 0.55rem; /* Adjust font size as needed */
  }

  .btn {
    padding: 0.25rem 0.5rem; /* Adjust padding as needed */
    font-size: 0.555rem; /* Adjust font size as needed */
    background-color: white; /* Add this line to set the background color to white */
  }
}

.workflow-node-form-text,
option {
  border-radius: 10px; /* Adjust the border radius as needed */
  font-family: Courier; /* Set the font to Courier */
  font-size: 0.7rem;
  border-width: 1px; /* Increase border thickness */
  padding: 0px; /* Decrease interior padding */
  border-color: var(--prussian-blue); /* Set border color to var(--prussian-blue) */
  text-align: center; /* Center the text */
}

.workflow-node-form-text-transparent,
option {
  border-radius: 10px; /* Adjust the border radius as needed */
  font-family: Courier; /* Set the font to Courier */
  font-size: 0.8rem;
  border: none; /* Remove the border */
  padding: 0px; /* Decrease interior padding */
  background-color: transparent; /* Set background to transparent */
  text-align: center; /* Center the text */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: visible; /* Allow text to overflow */
}

/* styles.css */
.workflow-edge-label {
  font-size: 12px;
  color: red;
  background-color: yellow;
  padding: 2px;
  border-radius: 3px;
}

.workflow-icon-button {
  transform: scale(1.2);
  color: rgba(0, 0, 0, 1);
  background-color: transparent;
  transition: color 0.3s, background-color 0.3s; // Ensure background-color transition is included
  line-height: 0;
  padding: 0 0.5rem; // Add left-right padding
  border: none; // Ensure no border is applied on hover
}

.workflow-icon-button:disabled {
  transform: scale(1.2);
  color: rgba(0, 0, 0, 0.2);
  background-color: transparent;
  transition: color 0.3s, background-color 0.3s; // Ensure background-color transition is included
  line-height: 0;
  padding: 0 0.5rem; // Add left-right padding
  border: none; // Ensure no border is applied on hover
}

.workflow-icon-button:not(:disabled):hover {
  color: rgba(0, 0, 0, 1);
  background-color: transparent !important; // Use !important to override other styles
  border: none; // Ensure no border is applied on hover
}

.workflow-filepond .filepond--file-info {
  font-size: 5px; /* Adjust the font size as needed */
  padding: 100%; /* Adjust the padding value as needed */
}

.filepond--label {
  font-size: 1.1rem !important; /* Adjust the font size as needed */
  /*border: 2px dashed #ccc;*/
}

.filepond-card-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  font-size: 1.75rem;
  width: 100%;
  height: 200px;
  border: 2px dashed #cccccc;
  border-radius: 10px; // Add rounded corners
  box-sizing: border-box;
}

.filepond--root {
  height: 175px;
  position: relative; // Add this
}

.filepond--drop-label {
  height: 100%;
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column; // Add this line
  justify-content: flex-start !important;
  align-items: flex-start; // This will override the previous align-items
  z-index: 1;
  width: 100%;
  align-items: center;
}

.feracloud-logo-upload {
  height: 65px;
  margin-top: 10px;
}

.feracloud-logo-upload.idle {
  opacity: 0.2;
}

.filepond-card-layout .filepond--item {
  font-size: 0.75rem; // Make the font size smaller
  padding: 0.5em; // Adjust padding to make the content more compact
  display: none;
}

.filepond-card-layout .filepond--panel-root {
  overflow: hidden; // Clearfix for the flex items if necessary
  font-size: 0.75rem; // Make the font size smaller
}

.filepond-card-layout .filepond--list:after {
  content: '';
  clear: both;
}

// Displayed on tables while they load
.table-loading-spinner {
  width: 25px; // Set a fixed width
  height: 25px; // Set a fixed height
  border: 3px solid rgba(0, 0, 0, 0.1); // Light border
  border-top: 4px solid #106fb7; // Darker border for the top
  border-radius: 50%; // Make it a circle
  animation: spin 1s linear infinite; // Add spinning animation
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.download-button {
  background-color: var(--white);
  color: var(--prussian-blue);
  border-color: var(--paynes-gray);
  line-height: 1; // Adjust line-height to center text vertically
  padding: 3px 12px; // Adjust padding as needed
  margin: 0; // Remove margin
  height: 100%; // Ensure button height matches row height
  display: flex;
  align-items: center;
  border-radius: 5px; // Add this line to make the borders more rounded
}

.download-button:hover {
  background-color: var(--paynes-gray);
  color: var(--white);
}

.radio-check-button {
  display: inline-block; // Ensure the div size is just around the button
  margin-right: 5px;
  padding: 0px 0px;
  background-color: #1c6fc200;
  color: #333;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e9ecef;
  }

  // Add styles for input and label within this div
  input[type='radio'],
  input[type='checkbox'] {
    display: none; // Hide the default radio button and checkbox
  }

  label,
  checkbox {
    background-color: white; // Set background to white
    color: var(--prussian-blue); // Set text color to prussian-blue
    border: 1px solid var(--prussian-blue); // Set border color to prussian-blue
    // ... existing code ...

    &:hover {
      background-color: var(--prussian-blue); // Inverse background color on hover
      color: white; // Inverse text color on hover
    }

    &.active {
      background-color: var(--prussian-blue); // Inverse background color when active
      color: white; // Inverse text color when active
    }
  }

  input[type='radio']:checked + label,
  input[type='checkbox']:checked + label,
  input[type='radio']:checked + checkbox,
  input[type='checkbox']:checked + checkbox {
    background-color: var(--prussian-blue); // Inverse background color when checked
    color: white; // Inverse text color when checked
  }
}

.fera-btn {
  background-color: #3c4b64;
  color: white;
  border-color: #3c4b64; /* Optional: if you want to change the border color as well */
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Add other styles like hover effects if needed */
}

.fera-btn:hover {
  background-color: darken(#184592, 10%); /* Darken the button on hover, for example */
}

.fera-btn-dark {
  background-color: var(--prussian-blue);
  color: white;
  border-color: #3c4b64; /* Optional: if you want to change the border color as well */
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Add other styles like hover effects if needed */
}

.fera-btn-dark:hover {
  background-color: lighten(#0d2b60, 10%); /* Darken the button on hover, for example */
}

.fera-btn-dark:disabled {
  background-color: #4f5051; /* Lighten the background color */
  color: lighten(white, 30%); /* Lighten the text color */
  border-color: lighten(#3c4b64, 30%); /* Lighten the border color */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  opacity: 0.6; /* Add opacity to make it look faded */
}

.save-btn:hover {
  background-color: green !important;
  border-color: green !important;
}

.cancel-btn:hover {
  background-color: rgb(139, 0, 0) !important;
  border-color: rgb(139, 0, 0) !important;
}

.header-button {
  margin-left: 40px;
  font-weight: bold;
  transition: opacity 0.3s;
  text-decoration: none; // Remove underline
  font-family: inherit; // Use the same font as the rest of the website
  color: #06304d99; // Set the color (slightly more faded)
  font-size: 0.9em; // Make the text smaller
}

.footer-button {
  transition: opacity 0.3s;
  text-decoration: none; // Remove underline
  font-family: inherit; // Use the same font as the rest of the website
  color: #02284f; // Set the color (slightly more faded)
  font-size: 1 em; // Make the text smaller
}

.sidebar-nav::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.sidebar-nav:hover::-webkit-scrollbar {
  width: 8px; /* Show scrollbar on hover */
}

.sidebar-nav::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5); /* Scrollbar color */
  border-radius: 4px;
}

.simplebar-wrapper {
  padding-bottom: 200px !important;
}

.fera-form {
  padding: 20px;
  background-color: #fff;

  .fera-form-section {
    padding-bottom: 20px;

    .fera-form-section-row {
      padding-bottom: 10px; /* Adjust the padding as needed */
    }
  }
}

.fera-form .CFormLabel {
  margin-bottom: 0px;
}

.fera-section-divider {
  border: 0;
  height: 1px;
  background-color: #383434;
  margin: 10px 0;
}

.fera-form .CRow {
  margin-bottom: 200px;
}

.experiment-metadata-preview {
  background-color: #f9f9f9; /* Light grey background */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Padding inside the box */
  margin-top: 10px; /* Space above the box */
  border: 1px solid #ddd; /* Light border */
}

.react-flow-select {
  font-size: 0.6rem;
}

.react-flow-select .react-select__control {
  min-height: 10px;
  background-color: var(--prussian-blue);
  border-color: var(--cadet-gray);
}

.react-flow-select .react-select__value-container {
  padding: 0;
}

.react-flow-select .react-select__dropdown-indicator {
  padding: 0;
  min-width: 5px;
  min-height: 5px;
  max-width: 10px;
  max-height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-flow-select .react-select__clear-indicator {
  padding: 2px;
}

.react-flow-select .react-select__input {
  margin: 0;
  padding: 0;
}

.react-flow-select .react-select__menu-portal {
  z-index: 9999;
}

.react-flow-select .react-select__menu {
  max-width: none; // Remove max-width restriction
  white-space: normal;
}

.react-flow-select .react-select__menu-list {
  white-space: normal; // Allow text to wrap
  max-height: 300px; // Increase the max height
}

.react-select__single-value {
  color: white;
  text-align: center; // Center the selected value text
  width: 100%; // Ensure the text takes up the full width
}

.react-select__placeholder {
  text-align: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.7); // Adjust the color and opacity as needed
}

.react-flow-select .react-select__option {
  color: var(--prussian-blue);
  background-color: white;
  white-space: normal; // Allow text to wrap
  word-wrap: break-word; // Break long words if necessary
  padding: 8px 12px; // Increase padding for better readability
}

/* Additional styles for consistency */
.react-flow-select .react-select__single-value {
  color: white;
}

.react-flow-select .react-select__option:hover,
.react-flow-select .react-select__option--is-focused {
  background-color: var(--cadet-gray);
}

.react-flow-select .react-select__option--is-selected {
  background-color: var(--prussian-blue);
}

.react-flow-select .react-select__indicator-separator {
  background-color: var(--cadet-gray);
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-animation {
  animation: rotate360 1s linear;
}

// Fera Modal Styles
.fera-modal {
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

.fera-modal-header {
  background-color: #303c54;
  color: white;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0.75rem 1rem; // Increased padding to accommodate the close button
  font-size: 1.1rem;
  font-weight: var(--cui-body-font-family);
  text-align: center;
  position: relative; // Added to contain the close button

  .btn-close {
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
      center/1em auto no-repeat;
    top: 0.5rem; // Adjust top position
    right: 0.5rem; // Adjust right position
    color: white;
    opacity: 0.8;
    font-size: 1rem; // Adjust size if needed
    padding: 0.25rem; // Add some padding for easier clicking

    &:hover {
      opacity: 1;
    }
  }
}

.fera-modal-body {
  padding: 1rem; // Reduce padding
  color: var(--prussian-blue);
  font-size: 1rem; // Slightly reduce font size
  width: 100%; // Ensure full width
}

.fera-modal-footer {
  border-top: none;
  padding: 1rem;
  justify-content: flex-end;
}

.fera-modal-button {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  color: var(--white);
  background-color: #555555;
  border-color: #555555;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.fera-modal-button-danger {
  background-color: #282138;
  border-color: #282138;
  color: var(--white);

  &:hover {
    background-color: darken(#dc3545, 10%);
    border-color: darken(#dc3545, 10%);
    color: var(--white);
  }

  &:disabled {
    background-color: lighten(#dc3545, 20%);
    border-color: lighten(#dc3545, 20%);
  }
}

@keyframes iconGrowAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0);
  }
}

.icon-animate-grow {
  animation: iconGrowAnimation 0.5s ease-in-out;
}

.icon-animate-rotate {
  animation: rotate360 1s linear;
}

.fera-expanded-table-row {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fera-expanded-table-row .card-header {
  background-color: #e9ecef;
  border-bottom: 1px solid #dee2e6;
  font-weight: bold;
}

.fera-expanded-table-row .card-body {
  padding: 1rem;
}

.fera-expanded-table-row p {
  margin-bottom: 0.5rem;
}

.fera-expanded-table-row strong {
  color: #495057;
}
.fera-row-tabs {
  border-bottom: 1px solid #dee2e6; // Add a bottom border to the tab container
}

.fera-row-tabs .nav-item {
  margin-bottom: -1px; // Pull the tabs down to overlap the container border
}

.fera-row-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #495057;
  background-color: transparent;
  padding: 0.5rem 1rem;
  margin-right: 0.25rem;
}

.fera-row-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  color: #007bff;
}

.fera-row-tabs .nav-link.active {
  color: #007bff;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.fera-tab-content {
  border: 1px solid #dee2e6;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 1rem;
  background-color: #ffffff;
}

.fera-tab-content-header {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--prussian-blue);
}

.fera-tab-content-subsection {
  margin-bottom: 1rem;
}

.instant-tooltip {
  transition: none !important;
}

.sidebar-backdrop {
  z-index: -1 !important;
}