// If you want to override variables do it here
@import 'variables';

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';
@import 'example';

// If you want to add custom CSS you can put it here.
@import 'custom';
.root-container {
  transform: scale(1);
  transform-origin: top left;
}

.small-container {
  transform: scale(0.75);
  transform-origin: top left;
  height: 133%;
  width: 133%;
}

.navbar-small-container {
  transform: scale(0.7);
  transform-origin: top left;
  height: 100%;
  width: 133%;
}

.test-container {
}

.foot-container {
  transform: scale(0.75);
  transform-origin: top left;
  height: 133%;
  width: 133%;
}

.browse-btn {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.browse-btn:hover {
  background-color: #c0c0c0; /* Slightly darker shade for hover */
}

.browse-btn:active {
  background-color: #dee2e6; /* Even darker for active/click */
}

.my-filepond-grid-layout .filepond--item {
  width: calc(15% - 1em); // Adjust the width for 4 columns
  // margin-right is no longer needed because gap takes care of spacing
  // display: inline-block is no longer needed because flexbox is used
}

.my-filepond-grid-layout .filepond--panel-root {
  overflow: hidden; // Clearfix for the flex items if necessary
}

.my-filepond-grid-layout .filepond--list:after {
  content: '';
  display: table;
  clear: both;
}

.my-filepond-grid-layout {
  display: flex; // Use flexbox for the container
  flex-wrap: wrap; // Allow items to wrap to the next line
  justify-content: center; // Center items along the main axis
  gap: 2em; // Create a gap between items
}

/* Progress container */
.overlay-progress-bar .c-progress {
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(157, 23, 23, 0.1); /* Subtle shadow */
  overflow: hidden; /* Ensures the inner bar's rounded corners are visible */
  background-color: #f5f5f5; /* Light grey background */
  margin-bottom: 1rem; /* Space below the progress bar */
}

/* Progress bar */
.overlay-progress-bar .c-progress-bar {
  background: linear-gradient(
    90deg,
    rgb(207, 207, 207) 0%,
    rgb(73, 51, 27) 100%
  ); /* Gradient fill */
  transition: width 0.5s ease-in-out; /* Smooth transition for width change */
  height: 20px; /* Custom height */
  border-radius: 8px; /* Consistent with the container's rounded corners */
}

.upload-btn {
  background-color: #3c4b64;
  border-color: #3c4b64; /* Optional: if you want to change the border color as well */
  /* Add other styles like hover effects if needed */
}

.upload-btn:hover {
  background-color: darken(#3c4b64, 10%); /* Darken the button on hover, for example */
}

.upload-btn:disabled {
  background-color: #3c4b64 !important; /* same as enabled color */
  color: white;
  opacity: 0.7; /* makes it look faded out */
  cursor: not-allowed; /* optional: changes the cursor on hover */
}

.hover-icon:hover {
  color: #8c0e0e !important; // Change the color to dark on hover
  opacity: 0.9; /* makes it look faded out */
}

.terminal {
  background-color: #10000008;
  color: #03011da4;
  padding: 10px;
  font-family: monospace;
  white-space: pre-wrap;
  overflow: auto;
  border-radius: 5px;
}

.row-spacing-20 {
  margin-top: 20px;
}

.disabled-form-input {
  background-color: #d3d3d3; /* This is a light gray color */
}

.fwdrev-button {
  background-color: #0e2446;
  color: #ffffff; /* You may want to set the text color too */
  margin-top: 10px;
}

.fwdrev-icon {
  color: white;
}
